@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primaryColor: #5656f1;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}
/* globals */
input:focus:valid {
  background-color: #ddffdd;
}
textarea:focus:valid {
  background-color: #ddffdd;
}
input:focus:invalid {
  background-color: #ffdddd;
}
textarea:focus:invalid {
  background-color: #ffdddd;
}

.scrollable-div {

 
  height: 350px;

  overflow: auto;

  margin: 20px;

  text-align: justify;

  padding: 20px;

}
h1 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: white;
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}

.phoneInput {
  margin-bottom: 10px;
}
label {
  margin-top: 10px;
}

.phoneInput input{
  padding: 5px;
  border: 2px solid black;
  border-radius: 5px;
}
.btn-warning {
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: var(--mainSpacing);
  color: white;
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}
.btn-primary:hover {
  text-decoration: none;
  border: 3px solid var(--primaryColor);
  background: rgba(0, 0, 0, 0.5) !important;
  color: #fff !important;
}
.btn-warning:hover {
  text-decoration: none;
  background: transparent !important;
  color: yellow !important;
}
.loading {
  text-transform: capitalize;
  margin: 0px auto;
  text-align: center;
  margin-top: 3rem;
}
.roomerror {
  margin-top: 100px;
}
.roomerror:hover .error {
  background-color: #2c3e50;
  color: #ffffff !important;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: var(--mainSpacing);
}

/* end of globals */
/* Navbar */
.nav-link {
  padding: 8px 15px;
  margin-left: 10px;
  margin-right: 20px;
  font-size: 18px;
  text-align: center;
  border: 2px solid transparent;
  transition: 0.5s linear;
  font-weight: 800;
  -webkit-transition: 0.5s linear;
  -moz-transition: 0.5s linear;
  -ms-transition: 0.5s linear;
  -o-transition: 0.5s linear;
}
.bg-transparent.scrolled {
  background-color: rgba(0, 0, 0, 0.85) !important;
  transition: 500ms ease;
}

.navbar-brand {
  font-size: 32px !important;
  color: #ffffff !important;
}
.nav-link:hover {
  color: white;
  transform: scale(1.1);
}
.nav-link.active_class {
  color: #ffffff;
  border: 2px solid #ffffff;
  font-weight: 800;
}

.form-control {
  border: 2px solid #000;
}
.form-control:focus,
.form-control.active {
  border: 2px solid #000000 !important;
}
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

/* end of navbar */
/* Hero */
.defaultHero,
.roomsHero {
  min-height: calc(100vh - 0px);
  background: url("./images/bg/Stolzhaus.JPG")
    center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.roomsHero {
  background-image: url("./images/bg/Stolz.jpg");
  min-height: calc(100vh - 0px);
}
.singleRoom {
  /*background-image: url("./images/room-3.jpeg");*/
  min-height: calc(100vh - 0px);
}
.roomsError {
  background-image: url("./images/error.jpg");
  min-height: calc(100vh - 0px);
}
.lost {
  font-size: 44px;
  color: yellow;
  text-align: center;
}
/* End of Hero */
/* Banner */
.banner {
  display: inline-block;
  position: absolute !important;
  background: rgba(0, 0, 0, 0.5) !important;
  color: var(--mainWhite);
  text-align: center;
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.banner h1 {
  font-size: 44px !important;
}
.banner div {
  width: 10rem;
  height: 5px;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}
.banner p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 576px) {  /*max 576px*/
  .banner {
    padding: 2rem 3rem !important;
  }
  .banner h1 {
    font-size: 32px !important;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 4rem;
  }
}
/* End of Banner */
/* Title */
.section-title {
  text-align: center;
  margin-bottom: 4rem;
}
.section-title h4 {
  font-size: 2rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: var(--primaryColor);
}
/* end of Title */

/* services */
.services {
  padding: 3rem 0;
}
.services {
  background: #ebf5fb;
  text-align: center;
}

.service span {
  display: inline-block;
  color: var(--primaryColor);
  font-size: 80px;
  margin-bottom: 1.5rem;
  transition: 1s linear;
  -webkit-transition: 1s linear;
  -moz-transition: 1s linear;
  -ms-transition: 1s linear;
  -o-transition: 1s linear;
}
.services span:hover {
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}
.services h6 {
  letter-spacing: var(--mainSpacing);
  font-weight: 1000;
  text-transform: capitalize;
}
.services p {
  width: 80%;
  margin: 0 auto;
}
@media screen and (min-width: 992px) {
  .services-center {
    width: 95vw;
    max-width: 1170px;
  }
}

@media screen and (min-width: 1200px) {
  .services p {
    width: 100%;
  }
}
/*end of services */
/* featured rooms */

.featured-rooms {
  padding: 5rem 0;
}

/* end pf featured rooms */
/* room */
.room {
  transition: 0.4s linear;
  -webkit-transition: 0.4s linear;
  -moz-transition: 0.4s linear;
  -ms-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  position: relative;
}
.room:hover {
  box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.5) !important;
}

.price-top {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: var(--mainWhite);
  padding: 0.3rem 0.6rem 0.5rem;
  border-bottom-right-radius: 1rem;
  font-size: 0.5rem;
  text-align: center;
  transition: 0.4s linear;
  -webkit-transition: 0.4s linear;
  -moz-transition: 0.4s linear;
  -ms-transition: 0.4s linear;
  -o-transition: 0.4s linear;
}
.price-top h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: var(--mainSpacing);
}
.room-link {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  color: white;
  transition: all 0.3s linear;
}
.room:hover {
  background: rgba(0, 0, 0, 0.8);
}
.room:hover img {
  opacity: 0.3;
}
.room:hover .price-top {
  opacity: 0;
}
.room:hover .room-link {
  opacity: 1;
  color: white;
  transform: translate(-100%, -180%) scale(1);
  -webkit-transform: translate(-60%, -260%) scale(1);
  -moz-transform: translate(-50%, -200%) scale(1);
  -ms-transform: translate(-50%, -200%) scale(1);
  -o-transform: translate(-50%, -200%) scale(1);
}
.room-info {
  background: #f2f3f4;
  text-transform: capitalize;
  padding: 0.5rem 0;
  text-align: center;
  font-weight: 700;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0;
}
/* end of room  */
/* single room*/

.single-room {
  padding: 5rem 0 0 0;
}
.single-room-images {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.single-room-images img {
  width: 100%;
  display: block;
}
.single-room-info {
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem auto;
}
.desc,
.info {
  margin: 1rem 0;
}
.desc h3 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.desc p {
  line-height: 1.5;
}
.info h3,
.info h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}

.info h6 {
  font-weight: 300;
}
.room-extras {
  width: 80vw;
  margin: 0 auto 3rem auto;
}
.room-extras h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.extras {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
@media screen and (min-width: 992px) {
  .single-room-images,
  .single-room-info,
  .room-extras {
    width: 95vw;
    max-width: 1170px;
  }
  .single-room-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  .info {
    padding-left: 3rem;
  }
}
/* end of single room*/
/* roomlist */
.roomslist {
  padding: 5rem 0;
}
.roomslist-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 30px;
}

@media screen and (min-width: 776px) {
  .roomslist-center {
    width: 90vw;
  }
}
@media screen and (min-width: 992px) {
  .roomslist-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of roomlist */
/*  rooms fitler*/
/* .filter-container {
  padding: 5rem 0;
}

.form-group {
  text-transform: capitalize;
}
.form-group label {
  display: block;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0.5rem;
} */

.single-extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

/* end of rooms fitler*/

/* aboutus */

.aboutus,
.contact {
  margin-top: 100px !important;
}
.about_company,
.team {
  padding: 60px 30px;
}
.about_info {
  letter-spacing: 40;
}
.carousel-inner {
  min-height: 400px !important;
  max-height: 400px !important;
}
.connect {
  font-size: 44px !important;
  padding: 5px 8px;
}
/* end of about us */



.splide__container {
  box-sizing: border-box;
  position: relative
}

.splide__list {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block
}

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto
}

.splide__progress__bar {
  width: 0
}

.splide {
  position: relative;
  visibility: hidden
}

.splide.is-initialized,
.splide.is-rendered {
  visibility: visible
}

.splide__slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative
}

.splide__slide img {
  vertical-align: bottom
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.splide__toggle.is-active .splide__toggle__play,
.splide__toggle__pause {
  display: none
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0
}

@keyframes splide-loading {
  0% {
      transform: rotate(0)
  }

  to {
      transform: rotate(1turn)
  }
}

.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.splide__track--fade>.splide__list {
  display: block
}

.splide__track--fade>.splide__list>.splide__slide {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 0
}

.splide__track--fade>.splide__list>.splide__slide.is-active {
  opacity: 1;
  position: relative;
  z-index: 1
}

.splide--rtl {
  direction: rtl
}

.splide__track--ttb>.splide__list {
  display: block
}

.splide__arrow {
  -ms-flex-align: center;
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 2em;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  z-index: 1
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em
}

.splide__arrow:hover:not(:disabled) {
  opacity: .9
}

.splide__arrow:disabled {
  opacity: .3
}

.splide__arrow:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px
}

.splide__arrow--prev {
  left: 1em
}

.splide__arrow--prev svg {
  transform: scaleX(-1)
}

.splide__arrow--next {
  right: 1em
}

.splide.is-focus-in .splide__arrow:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px
}

.splide__pagination {
  bottom: .5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1
}

.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: .7;
  padding: 0;
  position: relative;
  transition: transform .2s linear;
  width: 8px
}

.splide__pagination__page.is-active {
  background: #fff;
  transform: scale(1.4);
  z-index: 1
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: .9
}

.splide__pagination__page:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px
}

.splide.is-focus-in .splide__pagination__page:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px
}

.splide__progress__bar {
  background: #ccc;
  height: 3px
}

.splide__slide {
  -webkit-tap-highlight-color: transparent
}

.splide__slide:focus {
  outline: 0
}

@supports(outline-offset:-3px) {
  .splide__slide:focus-visible {
      outline: 3px solid #0bf;
      outline-offset: -3px
  }
}

@media screen and (-ms-high-contrast:none) {
  .splide__slide:focus-visible {
      border: 3px solid #0bf
  }
}

@supports(outline-offset:-3px) {
  .splide.is-focus-in .splide__slide:focus {
      outline: 3px solid #0bf;
      outline-offset: -3px
  }
}

@media screen and (-ms-high-contrast:none) {
  .splide.is-focus-in .splide__slide:focus {
      border: 3px solid #0bf
  }

  .splide.is-focus-in .splide__track>.splide__list>.splide__slide:focus {
      border-color: #0bf
  }
}

.splide__toggle {
  cursor: pointer
}

.splide__toggle:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px
}

.splide.is-focus-in .splide__toggle:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px
}

.splide__track--nav>.splide__list>.splide__slide {
  border: 3px solid transparent;
  cursor: pointer
}

.splide__track--nav>.splide__list>.splide__slide.is-active {
  border: 3px solid #000
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1)
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1)
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%)
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotate(-90deg)
}

.splide__arrows--ttb .splide__arrow--next {
  bottom: 1em;
  top: auto
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotate(90deg)
}

.splide__pagination--ttb {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: .5em;
  top: 0
}